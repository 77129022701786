import { HeadFC } from 'gatsby';
import React, { Suspense, lazy } from 'react';
import styled, { css } from 'styled-components';

import ErrorBoundary from '../components/ErrorBoundary';
import { Seo } from '../components/Seo';
import { DivLineStyle } from '../components/dividers/style';
import Loader from '../components/loading';
import { NavProvider } from '../context/nav';

const LetsWork = lazy(() => import('../components/sections/LetsWork'));
const OurTeam = lazy(() => import('../components/sections/OurTeam'));
const Welcome = lazy(() => import('../components/sections/Welcome'));
const Navbar = lazy(() => import('../components/design/navbar/Navbar'));
const Footer = lazy(() => import('../components/footer/Footer'));
const FeaturedWork = lazy(() => import('../components/sections/FeaturedWork'));
const AboutUs = lazy(() => import('../components/sections/AboutUs'));
// const Blog = lazy(() => import('../components/sections/Blog/Blog'));

export default function Home() {
  const isSSR = typeof window === 'undefined';
  if (isSSR) return null;
  return (
    <NavProvider>
      <ErrorBoundary>
        <Suspense fallback={Loader()}>
          <LayoutStyle>
            <Navbar />
            <MainStyle>
              <Welcome />
              <AboutUs />
              <DivLineStyle />
              <FeaturedWork />
              <DivLineStyle />
              <OurTeam />
              <DivLineStyle />
              {/* <Blog /> */}
              <DivLineStyle />
              <LetsWork />
            </MainStyle>
            <Footer />
          </LayoutStyle>
        </Suspense>
      </ErrorBoundary>
    </NavProvider>
  );
}

export const Head: HeadFC = () => <Seo />;

const LayoutStyle = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1440px;

  ${({ theme }) => css`
    ${theme.screens.mediaQueries.desktop} {
      margin: 0 auto;
    }
  `};
`;

const MainStyle = styled.main`
  overflow: hidden;

  ${({ theme }) => css`
    ${theme.screens.mediaQueries.desktop} {
      overflow: revert;
    }
  `};
`;
